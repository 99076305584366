<template>
  <div>
    <nav class="header uk-navbar uk-navbar-container uk-margin">
      <div class="contntainer">
       <!--<b-button class="navigation-button h1 mb-2" v-b-toggle.sidebar-no-header
          ><b-icon icon="list"></b-icon
        ></b-button>-->
        <div class="logo">
          <router-link :to="{ name: 'home' }" class="uk-navbar-item uk-logo">
            <img src="../../img/surf-logo.svg" alt="" />
            <p>Partick Thistle</p>
          </router-link>
        </div>
        <div class="header-buttons">

          <router-link :to="{ name: 'help' }">
           <p class="help">Help & support</p>
        </router-link>

        <router-link :to="{ name: 'Settings' }">
            <div class="user-photo-info">
            <img
              v-if="userData.userImage !== '' && userData.userImage !== null"
              :src="getImgUrl(userData.userImage)"
              alt=""
            />
            <img v-else :src="getImgUrl('default-image.png')" alt="" />
          </div>
        </router-link>
          <b-dropdown
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <b-icon icon="gear-fill" aria-hidden="true"></b-icon
              ><span class="sr-only">Settings</span>
            </template>
            <router-link :to="{ name: 'Settings' }">settings</router-link>
            <a href="javascript:;" @click="logout()">Logout</a>
          </b-dropdown>
        </div>
      </div>
    </nav>
    <b-sidebar
      id="sidebar-no-header"
      aria-labelledby="sidebar-no-header-title"
      no-header
      backdrop
      shadow
    >
      <template>
        <div class="p-3">
          <div class="logo">
            <router-link :to="{ name: 'home' }" class="uk-navbar-item uk-logo">
              <img src="../../img/surf-logo.svg" alt="" />
            </router-link>
          </div>
          <nav class="mb-3">
            <b-nav v-if="userData.pt_module !== '0'" vertical>
              <router-link :to="{ name: 'profile' }"
                ><img src="../../img/boot.svg" /> Your profile</router-link
              >
            </b-nav>
            <b-nav vertical>
              <router-link :to="{ name: 'teams' }"
                ><img src="../../img/strip.svg" /> Teams
                information</router-link
              >
            </b-nav>
            <b-nav vertical>
              <router-link
                v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
                :to="{ name: 'ptHome' }"
              >
                <img src="../../img/clock.svg" /> Personal Training</router-link
              >
              <router-link v-else :to="{ name: 'home' }">
                <img src="../../img/clock.svg" /> Personal Training</router-link
              >
            </b-nav>
          </nav>
        </div>
      </template>
    </b-sidebar>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { CHECK_PROFILE_HAS_DATA, USER_POPULATE } from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
export default {
  name: "App",
  data() {
    return {
      userData: {
        name: "",
        userType: "",
      },
    };
  },
  created() {
    this.$store.dispatch(CHECK_PROFILE_HAS_DATA).then((response) => {
      if (response === true) {
        this.setUser();
      } else {
        const clientId = JSON.parse(
          localStorage.getItem("the_w_selectedClientId")
        );
        this.getUserById(clientId).then((response) => {
          if (response === true) {
            this.selectedUserValue = clientId;
          }
        });
      }
    });
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile"]),
  },
  methods: {
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        return true;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT);
    },
  },
};
</script>
<style scoped lang="scss">

.header-buttons {
  padding-top: 0!important;
  display: flex!important;
  .user-photo-info {
    display: none;
    width: 4rem;

    img {
      width:100%;
      height:100%;
      border-radius: 100px;
      border: 1px solid #f4eead;
    }
  }

}


@media screen and (min-width: 1024px) {

  .header-buttons {
  .user-photo-info {
    display: block;
  }

}

}

</style>
