<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div class="uk-container uk-container-large">

      <div class="intro-header">
        <div class="header-buttons">
          <div v-if="selectedUserValue !== ''">
            <div class="user-photo-info">
              <img
                v-if="
                  selectedUserValue.userImage !== '' &&
                  selectedUserValue.userImage !== null
                "
                id="user_photo"
                :src="getImgUrl(selectedUserValue.userImage)"
                alt=""
              />
              <img v-else :src="getImgUrl('default-image.png')" alt="" />
              <h1>Coach <br> {{ selectedUserValue.name }}</h1>
            </div>
          </div>
          <h2 v-else>Coaches</h2>
        </div>
      </div>

      <template v-if="selectedUserValue !== ''">
        <ul class="tabs headding-space">
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'contact-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'contact-tab')"
              href="javascript:;"
              >Contact information</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'qualifications-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'qualifications-tab')"
              href="javascript:;"
              >Qualifications</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'targets-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'targets-tab')"
              href="javascript:;"
              >Targets</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'documents-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'documents-tab')"
              href="javascript:;"
              >Documents</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'teams-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'teams-tab')"
              href="javascript:;"
              >Teams</a
            >
          </li>
         <!---<li class="tab-item">
            <a
              :class="
                selectedTab === 'performace-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'performace-tab')"
              href="javascript:;"
              >Performace</a
            >
          </li>-->
        </ul>

        <div class="tabs-wrapper">
          <div
            class="current-week-tab tab-content"
            v-if="selectedTab === 'performace-tab'"
          >
        
        </div>

          <div
            class="current-week-tab tab-content"
            v-if="selectedTab === 'contact-tab'"
          >
            <div class="games-info">
              <div class="personal-info">
                <div class="user-photo-info">
                  <img
                    v-if="
                      selectedUserValue.coachImage !== '' &&
                      selectedUserValue.coachImage !== null
                    "
                    :src="getImgUrl(selectedUserValue.coachImage)"
                    alt=""
                  />
                  <img v-else :src="getImgUrl('default-image.png')" alt="" />
                </div>
                <div class="item">
                  <span class="label">Name:</span>
                  <span class="value">{{ selectedUserValue.name }}</span>
                </div>
                <div class="item">
                  <span class="label">Email:</span>
                  <span class="value">{{ selectedUserValue.email }}</span>
                </div>
                
                  <div class="item">
                    <span class="label">Phone:</span>
                    <span
                      class="value"
                      >{{ selectedUserValue.phone }}</span>
                  </div>

                  <div class="item">
                    <span class="label">Address:</span>
                    <span
                      class="value"
                      >{{ selectedUserValue.address }}</span>
                  </div>

                  <div class="item">
                    <span class="label">Postcode:</span>
                    <span
                      class="value"
                      >{{ selectedUserValue.post_code }}</span>
                  </div>

                <div class="item" v-html="selectedUserValue.details" />

                <b-button
                    @click="editPlayerProfileInSettings()"
                    class="main-button"
                    size="lg"
                    variant="outline-warning"
                    >Edit profile</b-button
                  >
              </div>
            </div>
          </div>

          <div
            class="team-over-veiw-tab tab-content"
            v-if="selectedTab === 'documents-tab'"
          >

          <div class="tab-content">

            <div class="games-info">

              <h4>Signed documents</h4>

                <b-button 
                v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
                @click="codeOfConductModal()"
                size="lg"
                class="main-button"
                variant="outline-warning"
                ><b-icon icon="pencil" aria-hidden="true"></b-icon
              > Code of conduct</b-button>

              <h4>Documents</h4>

              </div>


          </div>
          </div>

          <div
            class="team-over-veiw-tab tab-content"
            v-if="selectedTab === 'teams-tab'"
          >

          <div class="tab-content" v-if="clubTeams.length > 0">

            <div class="number-of-results">
              <p>Number of teams: {{ clubTeams.length }}</p>
            </div>

            <div
              v-for="(team, index) in clubTeams"
              :value="team.id + index"
              :key="team.id"
              class="team-profile-details"
              @click="setSelectedTeam(team)"
            >
              <div class="user-photo-info">
                <img :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="team-info">
                <h2>{{ team.teamName }}</h2>
                <p>Age group: {{ team.age_group }}</p>
              </div>
              <span
                class="team-form-results"
                v-if="allTeamsGameResultsData.length > 0 && userData.userType !== 'User'"
              >
                <span
                  v-for="(pastGame, i) in allTeamsGameResultsData"
                  :key="i"
                >
                  <span
                    v-if="team.id == pastGame.teamId"
                    :class="`result-${checkGameResult(pastGame)}`"
                    >{{ checkGameResult(pastGame) }}</span
                  >
                </span>
              </span>
              <span v-else>No game results</span>
            </div>


          </div>
          <div class="empty-state" v-else>
            <img src="../img/emptyStateIllustration.svg" />
            <p>No teams information</p>
          </div>
        </div>

        <div
            class="current-week-tab tab-content"
            v-if="selectedTab === 'targets-tab'"
          >

            <div class="games-info">

              <div class="player-game-targets">

                <div class="game-target">

                  <div class="in-possession">
                    <div class="targets">
                      <h4>Targets</h4>
                      <div v-for="target in coachGameTargetsData" :key="target.id">
                        <p class="target-outline">
                          <img src="./img/target.svg" alt="" /> <span v-html="target.target"></span> - <span v-if="target.complete == 1">Complete</span><span v-else>Not complete</span>
                          <b-button
                              size="lg"
                              class="main-button"
                              variant="outline-warning"
                              v-b-tooltip.hover
                              title="Edit"
                              @click="
                                performanceTargetsModal(
                                  target,
                                  'performanceTargetsModal',
                                  'performance target'
                                )
                              "
                              ><b-icon icon="pencil" aria-hidden="true"></b-icon
                            ></b-button>
                        </p>
                      </div>
                      <p v-if="coachGameTargetsData.length === 0">
                      No Game targets.</p>
                    </div>
                  </div>
                </div>

                <b-button
                        size="lg"
                        class="main-button"
                        variant="outline-success"
                        v-b-tooltip.hover
                        title="Add new Coach target"
                        @click="
                          performanceTargetsModal(
                            {},
                            'performanceTargetsModal',
                            'performance target'
                          )
                        "
                        ><b-icon icon="plus" aria-hidden="true"></b-icon
                      ></b-button>

                
                </div>

            </div>
          </div>

          <div
            class="current-week-tab tab-content"
            v-if="selectedTab === 'qualifications-tab'"
          >

          <div class="games-info">

            <div class="form__item qualifications-data">
              
              <div class="qualifications-wrapper">
                <img src="./img/uefa-logo.jpg" alt="uefa logo" />
                  <h4>UEFA Qualifications / coaching licences</h4>

                  <p v-if="selectedcoachesQualifications.UEFA_A === 1"><b-icon icon="award" aria-hidden="true"></b-icon> UEFA A</p>
                  <p v-if="selectedcoachesQualifications.UEFA_B === 1"><b-icon icon="award" aria-hidden="true"></b-icon> UEFA B</p>
                  <p v-if="selectedcoachesQualifications.UEFA_C === 1"><b-icon icon="award" aria-hidden="true"></b-icon> UEFA C</p>
                  <p v-if="selectedcoachesQualifications.UEFA_C === 1"><b-icon icon="award" aria-hidden="true"></b-icon> UEFA C</p>
                  <p v-if="selectedcoachesQualifications.UEFA_Pro === 1"><b-icon icon="award" aria-hidden="true"></b-icon> UEFA Pro</p>
                  <p v-if="selectedcoachesQualifications.youth_A_Elite === 1"><b-icon icon="award" aria-hidden="true"></b-icon> UEFA Youth A Elite</p>
                  <p v-if="selectedcoachesQualifications.youth_B_Elite === 1"><b-icon icon="award" aria-hidden="true"></b-icon> UEFA Youth B Elite</p>
                  <p v-if="selectedcoachesQualifications.youth_B_Elite === 1"><b-icon icon="award" aria-hidden="true"></b-icon> UEFA Goalkeeper A</p>
                  <p v-if="selectedcoachesQualifications.UEFA_Goalkeeper_A === 1"><b-icon icon="award" aria-hidden="true"></b-icon> UEFA Goalkeeper A</p>
                  <p v-if="selectedcoachesQualifications.UEFA_Goalkeeper_B === 1"><b-icon icon="award" aria-hidden="true"></b-icon> UEFA Goalkeeper B</p>
                  <p v-if="selectedcoachesQualifications.UEFA_Futsal_B === 1"><b-icon icon="award" aria-hidden="true"></b-icon> UEFA Futsal B</p>

                  <h4>Coaching experience</h4>
                  <div v-html="selectedcoachesQualifications.details"></div>
              </div>


              <div class="numbers-dates">
                <img src="./img/sfa-logo.jpg" alt="sfa logo" />
                <h4>Certificates and memebrships</h4>

                <p>Pvg scheme membership number
                  <span v-if="selectedcoachesQualifications.PVG_number !== null">{{selectedcoachesQualifications.PVG_number}}</span>
                  <span v-else class="red-warning">Please add membership number</span>
                </p>

                <p>Pvg scheme issue date
                  <span v-if="selectedcoachesQualifications.PVG_scheme_issue_date !== null">{{formatDateForHuman(selectedcoachesQualifications.PVG_scheme_issue_date)}}</span>
                  <span v-else class="red-warning">Please add issue date</span>
                </p>

                <p>Pvg scheme update due date
                  <span v-if="selectedcoachesQualifications.PVG_scheme_due_date !== null">{{formatDateForHuman(selectedcoachesQualifications.PVG_scheme_due_date)}}</span>
                  <span v-else class="red-warning">Please add due date</span>
                </p>


                <p>Disclosure certificate number
                  <span v-if="selectedcoachesQualifications.disclosure_certificate_number !== null">{{selectedcoachesQualifications.disclosure_certificate_number}}</span>
                  <span v-else class="red-warning">Please add certificate number</span>
                </p>


                <p>Child wellbeing certificate training completed
                  <span v-if="selectedcoachesQualifications.child_wellbeing_certificate_completed !== null">{{formatDateForHuman(selectedcoachesQualifications.child_wellbeing_certificate_completed)}}</span>
                  <span v-else class="red-warning">Please add issue date</span></p>


                <p>Child wellbeing certificate training (due date)
                  <span v-if="selectedcoachesQualifications.child_wellbeing_certificate_due_date !== null">{{formatDateForHuman(selectedcoachesQualifications.child_wellbeing_certificate_due_date)}}</span>
                  <span v-else class="red-warning">Please add due date</span></p>


                <p>First aid training completed
                  <span v-if="selectedcoachesQualifications.first_aid_completed !== null">{{formatDateForHuman(selectedcoachesQualifications.first_aid_completed)}}</span>
                  <span v-else class="red-warning">Please add completed date</span></p>


                <p>First aid training (due date)
                  <span v-if="selectedcoachesQualifications.first_aid_due_date !== null">{{formatDateForHuman(selectedcoachesQualifications.first_aid_due_date)}}</span>
                  <span v-else class="red-warning">Please add due date</span>
                </p>
              </div>

              <div class="continuous-professional-development">
                <img src="./img/sfa-logo.jpg" alt="sfa logo" />
                <h4>Continuous professional development (CPD)</h4>

                <div class="cpd-item-wrapper" v-if="cpdData.length > 0">

                  <div class="cpd-item" v-for="item in cpdData" :key="item.id">
                    <p>{{item.course_title}} 
                      <span>{{formatDateForHuman(item.date)}} - {{ item.hours }} CPD hours</span></p>
                    
                    <b-button
                    @click="addEditCPDhModel('edit', item)"
                    class="main-button"
                    size="lg"
                    variant="outline-warning"
                    ><b-icon icon="pencil" aria-hidden="true"></b-icon></b-button
                  >
                  </div>
                </div>
                <div class="empty-state" v-else>
                  <img src="../img/emptyStateIllustration.svg" />
                  <p>Please add CPD information</p>
                </div>


                <p class="total-hours">Total hours: {{ getCPDTotalHours() }}</p>
                <p class="small-text">You can change and up date this number when adding new CPD information.</p>

                <b-button
                  @click="addEditCPDhModel('add')"
                  class="main-button"
                  size="lg"
                  variant="outline-success"
                  >Add CPD attendance</b-button
                >
              </div>
            </div>

            <b-button
                  @click="addEditCoachModel('Edit')"
                  class="main-button"
                  size="lg"
                  variant="outline-warning"
                  >Edit qualifications</b-button
                >
          </div>
        </div>
        </div>
      </template>
      <template v-else>

        <template>
          <div class="tabs-wrapper" v-if="coachesData.length > 0">
          <section class="player-profile-wrapper">
            <div
              v-for="coach in coachesData"
              :value="coach.id"
              :key="coach.id"
              class="player-profile-details"
              @click="selectedUseChanged(coach)"
            >
              <div class="user-photo-info">
                <img
                  v-if="coach.userImage !== '' && coach.userImage !== null"
                  :src="getImgUrl(coach.userImage)"
                  alt=""
                />
                <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
              </div>
              <div class="user-info">
                <h2>{{ coach.name }}</h2>
                <p><span v-if="coach.userType === 'Admin'">{{ coach.userType }}</span> / Coach</p>
              </div>
            </div>
          </section>
          </div>

          <div class="empty-state" v-else>
            <img src="../img/emptyStateIllustration.svg" />
            <p>Please select a coach to view their information</p>
          </div>
        </template>
      </template>
    </div>

    <b-modal
          id="performanceTargetsModal"
          size="lg"
          :hide-footer="Boolean(true)"
        >
          <div class="performance-target-modal-content">
            <h3>
              <span v-if="selectedPerformanceTarget.edit === false">Add</span> 
              <span v-else>Edit</span> 
              Coach performance target</h3>
            <p class="small-date-player-coach">
              {{ formatDateForHuman(selectedPerformanceTarget.created_date) }}
            </p>

            <wysiwyg v-model="selectedPerformanceTarget.target" />
            <b-form-checkbox
              id="checkbox-2"
              size="lg"
              v-model="selectedPerformanceTarget.complete"
              name="checkbox-2"
              value="1"
              unchecked-value="0"
              >Mark target as complete
            </b-form-checkbox>

            <div v-if="selectedPerformanceTarget.edit === true" class="buttons-row">
              <b-button @click="updatePerformanceReportTarget()" size="lg" variant="outline-success"> Update</b-button>

              <b-button @click="deletePerformanceReportTarget()" size="lg" variant="outline-danger">Delete</b-button>
            </div>
            <b-button v-else @click="addNewPerformanceReportTarget()" size="lg" variant="outline-success"> Add</b-button>
          </div>
        </b-modal>


       <b-modal
      id="addEditCPD"
      size="lg"
      :title="editMode === false ? 'Add CPD information' : 'Edit CPD information'"
      :hide-footer="Boolean(true)">


      <div class="form__item">
          <label class="form__label" for="title"
            >Title</label
          >

          <b-form-input
          id="title"
          v-model="selectedCPDData.course_title"
        ></b-form-input>
      </div>


      <div class="form__item">
          <label class="form__label" for="sessionDetails"
            >Information</label
          >
          <wysiwyg v-model="selectedCPDData.course_information" />
        </div>


        <div class="form__item">
          <label class="form__label" for="pvg-scheme-membership-number"
            >Date attended / start date</label
          >

          <datepicker
            v-model="selectedCPDData.date"
            name="pvg-scheme-due-date"
            id="pvg-scheme-due-date"
          />
        </div>


        <div class="form__item">
          <label class="form__label" for="hours"
            >CPD Hours for this session</label
          >

          <b-form-input
          id="hours"
          type="number"
          v-model="selectedCPDData.hours"
        ></b-form-input>

        </div>

        <div class="form__item">
          <label class="form__label" for="total_hours"
            >Total CPD Hours (for current 3 year period) outstanding</label
          >

          <b-form-input
          id="total_hours"
          type="number"
          v-model="selectedCPDData.total_hours"
        ></b-form-input>

        </div>


        <div v-if="editMode === false" class="form__item">
          <button @click="saveCPDinformation()" class="green-button">Add infromation</button>
        </div>
        <div v-else class="form__item">
          <button @click="editCPDinformation()" class="green-button">Edit infromation</button>
        </div>

    </b-modal>




    <b-modal
      id="addEditCoach"
      size="lg"
      :title="editMode === false ? 'Add Qualifications' : 'Edit Qualifications'"
      :hide-footer="Boolean(true)">

        <div class="form__item">
          <label class="form__label" for="checkBoxes"
            >UEFA Qualifications / coaching licences</label
          >
          <div class="wrapper-checkBoxes">
            <b-form-checkbox
              id="UEFA_A-checkBox"
              v-model="selectedcoachesQualifications.UEFA_A"
              name="UEFA_A-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA A</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="UEFA_B-checkBox"
              v-model="selectedcoachesQualifications.UEFA_B"
              name="UEFA_B-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA B</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="UEFA_C-checkBox"
              v-model="selectedcoachesQualifications.UEFA_C"
              name="UEFA_C-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA C</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="UEFA_Pro-checkBox"
              v-model="selectedcoachesQualifications.UEFA_Pro"
              name="UEFA_Pro-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA Pro</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="youth_A_Elite-checkBox"
              v-model="selectedcoachesQualifications.youth_A_Elite"
              name="youth_A_Elite-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA Youth A Elite</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="youth_B_Elite-checkBox"
              v-model="selectedcoachesQualifications.youth_B_Elite"
              name="youth_B_Elite-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA Youth B Elite</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="UEFA_Goalkeeper_A-checkBox"
              v-model="selectedcoachesQualifications.UEFA_Goalkeeper_A"
              name="UEFA_Goalkeeper_A-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA Goalkeeper A</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="UEFA_Goalkeeper_B-checkBox"
              v-model="selectedcoachesQualifications.UEFA_Goalkeeper_B"
              name="UEFA_Goalkeeper_B-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA Goalkeeper B</span>
            </b-form-checkbox>

            <b-form-checkbox
              id="UEFA_Futsal_B-checkBox"
              v-model="selectedcoachesQualifications.UEFA_Futsal_B"
              name="UEFA_Futsal_B-checkBox"
              value="1"
              unchecked-value="0"
            >
              <span class="check-box-text">UEFA Futsal B</span>
            </b-form-checkbox>
          </div>
        </div>

        <div class="form__item">
          <label class="form__label" for="sessionDetails"
            >Coaching experience</label
          >
          <wysiwyg v-model="selectedcoachesQualifications.details" />
        </div>

        <h4>Certificates and memebrships</h4>
        <div class="form__item">
          <label class="form__label" for="pvg-scheme-membership-number"
            >Pvg scheme membership number</label
          >
          <b-form-input
          v-model="selectedcoachesQualifications.PVG_number" 
          id="pvg-scheme-membership-number">

          </b-form-input>
        </div>

        <div class="form__item">
          <label class="form__label" for="pvg-scheme-membership-number"
            >Pvg scheme issue date</label
          >

          <datepicker
            v-model="selectedcoachesQualifications.PVG_scheme_issue_date"
            name="pvg-scheme-issue-date"
            id="pvg-scheme-issue-date"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="pvg-scheme-membership-number"
            >Pvg scheme update due date</label
          >

          <datepicker
            v-model="selectedcoachesQualifications.PVG_scheme_due_date"
            name="pvg-scheme-due-date"
            id="pvg-scheme-due-date"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="disclosure-membership-number"
            >Disclosure certificate number</label
          >
          <b-form-input
          v-model="selectedcoachesQualifications.disclosure_certificate_number" 
          id="disclosure-membership-number">

          </b-form-input>
        </div>

        <div class="form__item">
          <label class="form__label" for="child-wellbeing-date-complete"
            >Child wellbeing certificate training completed</label
          >

          <datepicker
            v-model="selectedcoachesQualifications.child_wellbeing_certificate_completed"
            name="child-wellbeing-date-complete"
            id="child-wellbeing-date-complete"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="child-wellbeing-date-due-date"
            >Child wellbeing certificate training (due date)</label
          >

          <datepicker
            v-model="selectedcoachesQualifications.child_wellbeing_certificate_due_date"
            name="child-wellbeing-date-due-date"
            id="child-wellbeing-date-due-date"
          />
        </div>


        <div class="form__item">
          <label class="form__label" for="first-aid-completed"
            >First aid training completed</label
          >

          <datepicker
            v-model="selectedcoachesQualifications.first_aid_completed"
            name="first-aid-completed"
            id="first-aid-completed"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="first-aid-due-date"
            >First aid training (due date)</label
          >

          <datepicker
            v-model="selectedcoachesQualifications.first_aid_due_date"
            name="first-aid-due-date"
            id="first-aid-due-date"
          />
        </div>

        <div v-if="editMode === false" class="form__item">
          <button @click="addCoachQualificationsSubmit()" class="green-button">Add Qualifications</button>
        </div>
        <div v-else class="form__item">
          <button @click="editCoachQualificationsSubmit()" class="green-button">Edit Qualifications</button>
        </div>
    </b-modal>

    <b-modal
              id="addHomeworkModal"
              size="lg"
              :hide-footer="Boolean(true)"
            >

            <h2><span v-if="newHomeWorkData.id !== undefined">Edit</span><span v-else>Add</span> homework</h2>

            <div class="form__item">
              <label class="form__label" for="goals"
                >Title</label
              >
              <b-form-input
                id="textarea"
                v-model="newHomeWorkData.title"
              ></b-form-input>
            </div>


            <div class="form__item">
              <label class="form__label" for="goals"
                >Information</label
              >
              <wysiwyg v-model="newHomeWorkData.information" />
            </div>

            <h4>Videos</h4>

            <b-button
                  v-b-tooltip.hover
                  title="Add video"
                  @click="addVideo('homework')"
                  variant="success"
                >
                  <b-icon icon="plus" aria-hidden="true"></b-icon>
                </b-button>


                <b-button  v-if="newHomeWorkData.id !== undefined" @click="editHomework()" size="lg" variant="outline-success"
                  ><b-icon icon="file-text" aria-hidden="true"></b-icon> Edit
                  game homework</b-button
                >

                <b-button v-else @click="saveHomework()" size="lg" variant="outline-success"
                  ><b-icon icon="file-text" aria-hidden="true"></b-icon> Submit
                  game homework</b-button
                >
            </b-modal>



            <b-modal id="codeOfConductModal" modal-class="modal-fullscreen" size="xl" title="Code of conduct" :hide-footer="Boolean(true)">
          <div class="player-cv-content">


            <div class="form__item">
               
                <div v-html="codeOfConductData.text"></div> 

                <h4>Signature</h4>
                <p  v-if="codeOfConductSignature.signature_change === true">Please sign below</p>
                <div class="signatureWrapper">
                  <vueSignature
                    v-if="codeOfConductSignature.signature_change === true"
                    ref="playerSignature"
                    :sigOption="signatureOptions"
                    :w="'800px'"
                    :h="'400px'"
                    :disabled="false"
                  ></vueSignature>
                  <img v-else :src="getImgUrl(codeOfConductSignature.signature)"/>

                  <div  v-if="codeOfConductSignature.signature_change === true" class="player-game-responce">
                    <button @click="saveSignature('playerSignature')">
                      Save
                    </button>
                    <button @click="clearSignature('playerSignature')">
                      Clear
                    </button>
                  </div>
                  <div v-else class="player-game-responce">
                    <button @click="changeSignature('player_signature')">
                      Edit
                    </button>
                  </div>
                </div>

            </div>

          </div>
        </b-modal>


  </div>
</template>
<script>
import SpinnerLoader from "../../components/LoadingSpinning";
import AppTemplate from "../layouts/App";
import { mapGetters } from "vuex";
import {
  CHECK_PROFILE_HAS_DATA,
  USER_POPULATE,
  POPULATE_COACHES_USER_DATA,
} from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import { Coaches } from "@/services/coaches-service.js";
import { Teams } from "@/services/teams-service.js";
import { Games } from "@/services/games-service.js";
import { Documents } from "@/services/documents-service.js";
import { PerformanceReview } from "@/services/performance-review-service.js";
import vueSignature from "vue-signature";
import extendSeasonEndDateToFullYearMixin from "@/mixin/extendSeasonEndDateToFullYear.js";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  name: "Coaches",
  mixins: [
    extendSeasonEndDateToFullYearMixin,
  ],
  components: {
    AppTemplate,
    SpinnerLoader,
    Datepicker,
    vueSignature,
  },
  data() {
    return {
      loadingStuff: false,
      editMode: false,
      selectedPerformanceTarget: {
        edit: false,
      },
      signatureOptions: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      coachGameTargetsData: [],
      coachesData: [],
      coachesUsersData: [],
      clubTeams: [],
      allTeamsGameResultsData: [],
      selectedcoachesQualifications: {
        UEFA_A: 0,
        UEFA_B: 0,
        UEFA_C: 0,
        UEFA_Futsal_B: 0,
        UEFA_Goalkeeper_A: 0,
        UEFA_Goalkeeper_B: 0,
        UEFA_Pro: 0,
        details: "",
        youth_A_Elite: 0,
        youth_B_Elite: 0,
      },
      selectedTab: "contact-tab",
      userData: {
        name: "",
        userType: "",
      },
      userHeaderData: {
        name: "",
        userType: "",
      },
      codeOfConductSignature: {
        signature: null,
        signature_change: true,
      },
      selectedCPDData: {},
      cpdData: [],
      codeOfConductData: {},
      newHomeWorkData: {},
      selectedUserDataForHeader: {},
      selectedUserValue: "",
      weekSelectedNumber: "",
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      image: {
        imageName: "",
        path: "",
        file: null,
      },
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getCoachesUsersData"]),
  },
  created() {
    this.loadingStuff = true;
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    this.setMonToSundayWeekDates();
    this.checkSeasionDates();

    const clientId = JSON.parse(localStorage.getItem("the_w_selectedClientId"));
    this.getUserByIdForHeader(clientId);
    this.getUserById(clientId).then((response) => {
      if (response === true) {

        if (this.userData.userType === "Coach") {
          this.populateCoachesData();
        }

        if (this.userData.userType === "Admin") {
          this.getAllCoaches();
        }
      }
    });
    this.loadingStuff = false;
  },
  methods: {
    getCPDTotalHours() {
      return this.cpdData.length > 0 ? this.cpdData[0].total_hours : 15;
    },
    addEditCPDhModel(edit, item) {
      if (edit === "edit") {
        this.editMode = true;
        this.selectedCPDData = item;
       }
      this.showModal('addEditCPD');
    },
    async getCPDinformationforCoach() {
      const userData = {
        coach_id: this.selectedUserValue.id,
        action: "getCPDinformationforCoach",
      }

      const responce = await Coaches.find(userData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.status === 200) {
        this.cpdData = responce.data;
      }
    },
    async saveCPDinformation() {
      this.selectedCPDData.date = moment(this.selectedCPDData.date).format("YYYY-MM-DD");
      const userData = {
        ...this.selectedCPDData,
        coach_id: this.selectedUserValue.id,
        action: "saveCPDinformation",
      }

      const responce = await Coaches.find(userData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.status === 200) {
        this.getCPDinformationforCoach();
        this.$bvModal.hide('addEditCPD');
        this.$bvToast.toast("CPD information saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.selectedCPDData = {};
      }
    },
    async editCPDinformation() {
      const userData = {
        ...this.selectedCPDData,
        action: "editCPDinformation",
      }

      const responce = await Coaches.find(userData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.status === 200) {
        this.getCPDinformationforCoach();
        this.$bvModal.hide('addEditCPD');
        this.$bvToast.toast("CPD information updated.", {
          title: "updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });

        this.selectedCPDData = {};
        this.editMode = false;
      }
    },
    async saveCodeOfConductSignatureCall(image, signatureName) {
      const userData = {
        fileName: signatureName,
        imageFile: image,
        coach_id: this.selectedUserValue.id,
        action: "saveCodeOfConductSignatureCall",
        created_date: moment().format("YYYY-MM-DD"),
      }

      const responce = await Documents.find(userData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.status === 200) {
        this.getCodeOfConductData();
        this.getCodeOfConductSignatures();
        this.$bvModal.hide('codeOfConductModal');
        this.$bvToast.toast("Signature saved.", {
          title: "saved",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async getCodeOfConductSignatures() {
      const userData = {
        coach_id: this.selectedUserValue.id,
        action: "getCodeOfConductSignatures",
      }

      const responce = await Documents.find(userData).catch(
        (error) => {
          console.log(error);
        }
      );

      if (responce.status === 200 && typeof responce.data !== "boolean") {
        this.codeOfConductSignature = responce.data;
      } 
    },
    saveSignature(signatureName) {
      var _this = this;
      var jpeg = _this.$refs[signatureName].save("image/jpeg");
      this.saveCodeOfConductSignatureCall(jpeg, signatureName);
    },
    async getCodeOfConductData() {
      const userData = {
        action: "getCodeOfConductData",
        clubId: this.selectedUserValue.clubId,
      };
      const response = await Documents.find(userData).catch((error) => {
        console.log(error);
      });

      if(response.status === 200) {
        this.codeOfConductData = response.data;
      }
    },
    codeOfConductModal() {
      this.showModal('codeOfConductModal');
    },
    checkSeasionDates() {
      if (localStorage.getItem("currentPlayingseasonStartDate") === null) {

        let year = moment().year();
        let yearAndOne = moment().add(1, 'year').year();

        localStorage.setItem(
          "currentPlayingseasonStartDate",
          `${year}-06-30`
        );
        localStorage.setItem(
          "currentPlayingseasonEndDate",
          `${yearAndOne}-08-01`
        );

        localStorage.setItem(
          "currentPlayingSeason",
          `${year}`
        );

      }

    },
    checkIntTargetValue(value) {
      if (value !== undefined && value != 0) {
        return 1;
      } else {
        return 0;
      }
    },
    makeTheNewPerformanceReportTargetObject() {
      this.selectedPerformanceTarget.complete = this.checkIntTargetValue(this.selectedPerformanceTarget.complete);

      return {
        player_id: this.selectedUserValue.id,
        performance_report_id: 0,
        idp_id: 0,
        target: this.selectedPerformanceTarget.target,
        complete: this.selectedPerformanceTarget.complete,
        category: "",
        created_date: moment().format("YYYY-MM-DD"),
        possession: "",
        action: "addNewPerformanceReportTarget",
      };
    },
    async getCoachTargetsById() {
      const userData = {
        userId: this.selectedUserValue.id,
        action: "getCoachTargetsById",
        season_start_date: localStorage.getItem("currentPlayingseasonStartDate"),
        season_end_date: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });
      
      this.coachGameTargetsData = responce.data;
    },
    async deletePerformanceReportTarget() {
      const userData = {
        id: this.selectedPerformanceTarget.id,
        action: "deletePerformanceReportTarget",
      };

      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      })

      if (responce.data === "Performance report target deleted") {
        this.getCoachTargetsById();
        this.$bvModal.hide("performanceTargetsModal");
        this.$bvToast.toast("Performance report target deleted", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async updatePerformanceReportTarget() {
      const userData = {
        ...this.selectedPerformanceTarget,
        action: "updatePerformanceReportTarget",
      };
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Performance report target updated") {
        this.getCoachTargetsById();
        this.$bvModal.hide("performanceTargetsModal");
        this.$bvToast.toast("Performance report target updated", {
          title: "Updated",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async addNewPerformanceReportTarget() {
      const userData = this.makeTheNewPerformanceReportTargetObject();

      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "Performance report target added") {
        this.getCoachTargetsById();
        this.$bvModal.hide("performanceTargetsModal");
        this.$bvToast.toast("Performance report target added", {
          title: "added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    performanceTargetsModal(data, modelName, typeOfTarget) {
      if (Object.keys(data).length > 0) {
        this.selectedPerformanceTarget = data;
        this.selectedPerformanceTarget.edit = true;
      } else {
        this.selectedPerformanceTarget.edit = false;
      }
      this.selectedPerformanceTarget.typeOfTarget = typeOfTarget;
      this.$bvModal.show(modelName);
    },
    editPlayerProfileInSettings() {
      this.$router.push("/settings").catch(() => {});
    },
    setSelectedTeam(team) {
      this.$router.push({
          name: "teams",
          params: { teamData: team },
        });
    },
    checkGameResult(game) {
      if (Number(game.home_team_goals) > Number(game.away_team_goals)) {
        return "W";
      }
      if (Number(game.home_team_goals) === Number(game.away_team_goals)) {
        return "D";
      }
      if (Number(game.home_team_goals) < Number(game.away_team_goals)) {
        return "L";
      }
    },
    async getCoachesQualificationsById() {
      const userData = {
        coach_id: this.selectedUserValue.id,
        clubId: this.selectedUserValue.clubId,
        action: "getCoachesQualificationsById",
      };

      const responce = await Coaches.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== null) {
        this.selectedcoachesQualifications = responce.data;

        if (typeof responce.data === "string") {
          this.getCoachesQualificationsById();
        }
      }
    },
    async getTeamsDataById(teamId) {
      const userData = {
        coach_id: this.selectedUserValue.id,
        action: "getTeamsByCoachid",
      };
      const responce = await Teams.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data !== null) {
        Array.isArray(responce.data)
          ? (this.clubTeams = responce.data)
          : (this.clubTeams = [responce.data]);
      }

      const allTeamsIds = this.getAllTeamsIdsFromTeamsEvents();
        this.getAllTeamsPastResults(
          this.formatMatchDate(moment().subtract(3, "months")),
          this.formatMatchDate(moment()),
          allTeamsIds
        );
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;

      if (this[tabValue] === "targets-tab") {
        this.getCoachTargetsById();
      }

      if (this[tabValue] === "documents-tab") {
        this.getCodeOfConductData();
        this.getCodeOfConductSignatures();
      }
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    clearImageDataFromUpload() {
      (this.imageURL = ""),
        (this.image = {
          imageName: "",
          path: "",
          file: null,
        });
    },
    imageUploadObjectDataChanged(data) {
      this.image = data;
    },
    imageUploadFileDataChnged(data) {
      this.imageFile = data;
    },
    selectedUseChanged(coach) {
      this.selectedUserValue = coach;
      this.getTeamsDataById(coach.teamId);
      this.getCoachesQualificationsById();
      this.getCPDinformationforCoach();
    },
    weekSelectedChanged(number) {
      this.weekSelectedNumber = number.weekSelectedNumber;
    },
    mondaySunDatesChanged(data) {
      this.selectedWeekMonSunday = data.monToFri;
    },
    setMonToSundayWeekDates() {
      const year = JSON.stringify(moment().year());
      const monday = moment()
        .year(year)
        .isoWeek(this.weekSelectedNumber)
        .day("monday")
        .startOf("day");
      this.selectedWeekMonSunday =
        monday.format("MMM D - ") + monday.add(6, "days").format("D YYYY");
      // this.depatchMonToSundayWeekDatesInLocalStorage();
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        return true;
      }
    },
    async getUserByIdForHeader(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.userHeaderData = responce.data[0];
      }
    },
    populateCoachesData() {
      const data = [{
        clubId: this.userData.clubId,
        coach_id: 0,
        email: this.userData.email,
        id: this.userData.id,
        joined_date: this.userData.joined_date,
        name: this.userData.name,
        phone: this.userData.phone,
        teamId: 0,
        userImage: this.userData.userImage,
        userType: this.userData.userType,
        address: this.userData.address,
        post_code: this.userData.post_code,
        
      }]

      this.coachesData = data;
    },
    async getAllCoaches() {
      const userData = {
        action: "getAllCoachesByClubId",
        clubId: this.userData.clubId,
      };

      const responce = await Coaches.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.coachesData = responce.data;
      }
    },
    convertCoachQualificationsDates() {
      const dateFields = [
        'PVG_scheme_issue_date',
        'PVG_scheme_due_date',
        'child_wellbeing_certificate_completed',
        'child_wellbeing_certificate_due_date',
        'first_aid_completed',
        'first_aid_due_date'
      ];

      dateFields.forEach(field => {
        const dateValue = this.selectedcoachesQualifications[field];
        if (dateValue !== null) {
          this.selectedcoachesQualifications[field] = this.formatMatchDate(dateValue);
        }
      });
    },
    async addCoachQualificationsSubmit() {
      this.convertCoachQualificationsDates();
      const userData = {
        ...this.selectedcoachesQualifications,
        coach_id: this.selectedUserValue.id,
        clubId: this.selectedUserValue.clubId,
        action: "addCoachQualifications",
      };
      const responce = await Coaches.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.getCoachesQualificationsById();
        this.loadingStuff = false;
        this.$bvModal.hide("addEditCoach");
        this.$bvToast.toast("Qualifications added.", {
          title: "Added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async editCoachQualificationsSubmit() {
      this.convertCoachQualificationsDates();
      const userData = {
        ...this.selectedcoachesQualifications,
        coach_id: this.selectedUserValue.id,
        clubId: this.selectedUserValue.clubId,
        action: "editCoachesQualifications",
      };

      const responce = await Coaches.find(userData).catch((error) => {
        console.log(error);
      });


      if (responce.status === 200) {
        this.getCoachesQualificationsById();
        this.loadingStuff = false;
        this.$bvModal.hide("addEditCoach");
        this.$bvToast.toast("Coach qualifications updated.", {
          title: "Added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async addEditCoachModel(value) {
      if (value === "Edit") {
        this.editMode = true;
      } else {
        this.resetSelectedCoach();
        this.editMode = false;
      }
      this.showModal("addEditCoach");
    },
    resetSelectedCoach() {
      this.selectedcoachesQualifications = {
        UEFA_A: 0,
        UEFA_B: 0,
        UEFA_C: 0,
        UEFA_Futsal_B: 0,
        UEFA_Goalkeeper_A: 0,
        UEFA_Goalkeeper_B: 0,
        UEFA_Pro: 0,
        details: "",
        youth_A_Elite: 0,
        youth_B_Elite: 0,
      };
      this.editMode = false;
    },
    setHeaderUserData() {
      if (this.getProfile !== null) {
        this.userHeaderData = this.getProfile;
      }
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    formatMatchDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatDateForHuman(date) {
      return date !== undefined && moment(date).isValid()
        ? moment(date).format("MMMM Do YYYY")
        : "";
    },
    getAllTeamsIdsFromTeamsEvents() {
      return this.clubTeams.map((event) => event.id);
    },
    async getAllTeamsPastResults(
      gameStartDateAndTime,
      gameEndDateAndTime,
      allTeamsIds
    ) {
      const data = {
        action: "getAllTeamsPastResults",
        teamIds: allTeamsIds,
        gameStartDateAndTime: gameStartDateAndTime,
        gameEndDateAndTime: gameEndDateAndTime,
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allTeamsGameResultsData = responce.data;
      }
    },
  },
};
</script>
<style lang="scss" scoped>


input#hours, input#total_hours {
    width: 5rem;
}

.signatureWrapper {
   div {
  border: 1px solid #d6dadd;
  margin-bottom: 1rem;
   }

   div.player-game-responce {
    border: none;
  }
}

.qualifications-data {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 1rem;
}

.qualifications-wrapper, .numbers-dates, .continuous-professional-development {

  border: 1px solid #c3cdd5;
    border-radius: 4px;
    padding: 2rem;
    margin-bottom: 2rem;

    img {
      width: 6rem;
      display: block;
      margin: 0 auto 1rem auto;
    }

}

.continuous-professional-development {

  .cpd-item-wrapper {
    background: #fbfdff;
    padding: 1rem;
    margin: 1rem 0;
    height: 20.3rem;
    overflow:scroll;
    border: 1px solid #c3cdd5;

    .cpd-item {
    border: 1px solid #c3cdd5;
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    display:flex;
    flex-wrap:wrap;
    gap:1rem;
    justify-content: space-between;
    background: white;

    p, button {
      margin:0;
    }

    span {
      display:block;
      font-size: 0.8rem;
    }
  }

  }

  .total-hours {
    margin:0;
    font-size: 2rem;
    font-weight: bold;
  }

  .small-text {
    font-size: 0.8rem;
  }

}

.numbers-dates {
  p {
    span {
      display: block;
      font-weight:bold;
    }
  }

}

.intro-header {
  border: none;
}

.number-of-results {
    width: 100%;
}

.qualifications-wrapper {
  margin-bottom: 2rem;
}

.targets {
      border: 1px solid #efefef;
      padding: 1rem;
      border-radius: 4px;
      margin-bottom:1rem;

      h4 {
          font-size: 1rem;
        }

      
      .target-outline {
        img {
          width:2rem;
        }

        button {
          margin-top:1rem;
          display: block;
        }
      }
      


    }

</style>
