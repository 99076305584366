<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />

    <div class="uk-container uk-container-large">

    <ul class="tabs">
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'schedule-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'schedule-tab')"
              href="javascript:;"
              >Schedule</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'pitch-tab' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'pitch-tab')"
              href="javascript:;"
              >Pitch booking</a
            >
          </li>
    </ul>

    <div class="tabs-wrapper">
          <div
            class="current-week-tab tab-content"
            v-if="selectedTab === 'schedule-tab'"
          >

          <div class="mins-stats-wrapper">

            <button
        @click="openAddEventModal()"
        v-if="userData.userType === 'Admin' || userData.userType === 'Coach'"
        class="green-button add-new-programme"
      >
        Add event
      </button>
          <div class="cal-buttons-wrapper">

            <a
              class="cal-buttons"
              href="javascript:;"
              @click="changeMonth(-1, 'minsData')"
              >Previous month</a
            >
            <a
              class="cal-buttons"
              href="javascript:;"
              @click="changeMonth(1, 'minsData')"
              >Next month</a
            >
            </div>
            <div class="date-year">{{ getMonthName(minsData.month) }} | {{ minsData.year }}</div>

            <div class="mins-stats-view">

              <section class="col-one-teams">
                <div class="body">Team</div>
                <div :class="`body team-${team.id}`" v-for="team in clubTeams" :key="team.id">{{team.teamName }}</div>
              </section>

              <section class="events">

                <div
                v-for="weekDay, index in minsData.weekDays" :key="index"
                class="mins-stats-col-day-wrapper"
              >
                <div class="mins-stats-col-day">
                  <div class="head">{{ getDayFromDate(weekDay.date) }}</div>
                  <div class="body" :class="`body team-${team.id}`" v-for="team in clubTeams" :key="team.id">

                      <div v-for="game in scheduleData" :key="game.id">

                        <div v-if="team.id == game.teamId && formatDateForDB(weekDay.date) === game.date">
                          <div v-if="game.gameType === 'Training'" class="event-wrapper training-style">
                            <span class="event-text" v-b-tooltip.hover :title=" game.title">{{ game.title }}
                          </span>
                          <span><span v-if="game.pitch_zone !== null">Pitch zone - {{game.pitch_zone}}</span> {{ game.time }}</span>
                        </div>

                        <div v-if="game.gameType === 'League game'" class="event-wrapper league-style">
                            <span class="event-text" v-b-tooltip.hover :title=" game.title">{{ game.title }}
                          </span>
                          <span><span v-if="game.pitch_zone !== null">Pitch zone - {{game.pitch_zone}}</span> {{ game.time }}</span>
                        </div>

                        <div v-if="game.gameType === 'Cup game'" class="event-wrapper cup-style">
                            <span class="event-text" v-b-tooltip.hover :title=" game.title">{{ game.title }}
                          </span>
                          <span><span v-if="game.pitch_zone !== null">Pitch zone - {{game.pitch_zone}}</span> {{ game.time }}</span>
                        </div>

                        <div v-if="game.gameType === 'Other'" class="event-wrapper">
                            <span class="event-text" v-b-tooltip.hover :title=" game.title">{{ game.title }}
                          </span>
                          <span>{{ changeTimeFrom24To12jours(game.time)}} - {{ changeTimeFrom24To12jours(game.endTime) }}</span>
                        </div>
                      </div>
                    </div>
                    

              
                  </div>
                </div>
              </div>
                
              </section>


             
              </div>
            </div>
            </div>

        </div>

        <div
            class="current-week-tab tab-content"
            v-if="selectedTab === 'pitch-tab'"
          >

          <Calendar />
        
        
      </div>

    </div>

    <b-modal
      id="addEvent"
      size="lg"
      title="Add an event"
      :hide-footer="Boolean(true)"
    >

    <div class="event-schedual-club">

      <div class="form__item">
          <label class="form__label" for="name">Title</label>
          <input
            class="form__input"
            type="text"
            v-model="newEventData.title"
            id="name"
          />
      </div>

        <div class="form__item">
          <label class="form__label" for="start">Start time</label>
          <input
            class="form__input"
            type="time"
            v-model="newEventData.start_time"
            min="06:00" max="23:00"
            id="start"
          />
      </div>

      <div class="form__item">
          <label class="form__label" for="end">End time</label>
          <input
            class="form__input"
            type="time"
            v-model="newEventData.end_time"
            min="06:00" max="23:00"
            id="end"
          />
      </div>

      <div class="form__item uk-form dob">
            <label class="form__label" for="event_date">event Date</label>
            <datepicker
              v-model="newEventData.date"
              name="event_date"
              id="event_date"
            />
          </div>

      <div class="form__item">
                  <label class="form__label" for="userSelect"
                    >Select team</label
                  >

                  <div class="user-select-wrapper">
                    <div v-for="team in clubTeams" :key="team.id">
                      <input
                      @change="ageGroupAddRemove($event)"
                        type="checkbox"
                        :id="team.id"
                        :name="team.teamName"
                        :value="team.id"
                        :checked="team.selected"
                      />
                      <label :for="team.id">{{ team.teamName }}</label
                      ><br />
                    </div>
                  </div>
                </div>

                <button
        @click="saveEvent()"
        class="green-button add-new-programme"
      >
        Add event
      </button>


    </div>
        

    
    </b-modal>

    </div>
</template>
<script>
import SpinnerLoader from "../../components/LoadingSpinning";
import AppTemplate from "../layouts/App";
import { CHECK_PROFILE_HAS_DATA } from "@/store/actions/user.js";
import { mapGetters } from "vuex";
import Calendar from "../../components/Calendar";
import { Teams } from "@/services/teams-service.js";
import { Users } from "@/services/user-service.js";
import { Games } from "@/services/games-service.js";
import dayjs from 'dayjs';
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import extendSeasonEndDateToFullYearMixin from "@/mixin/extendSeasonEndDateToFullYear.js";

export default {
  name: "Booking",
  mixins: [
    extendSeasonEndDateToFullYearMixin,
  ],
  data() {
    return {
      loadingStuff: false,
      selectedTab: "schedule-tab",
      newEventData: {},
      userData: {},
      clubTeams: [],
      allTeamDataBeforFilterApplied: [],
      teamGamesDataUnfilterded: [],
      teamGamesData: [],
      scheduleData: [],
      listOfSelectedYear: [],
      listOfSelectedYearToRemove: [],
      scheduleStartDate: "",
      scheduleEndDate: "",
      minsData: {
        year: 2024,
        month: 5,
        weekDays: [
          {
            day: "",
            date : "",
          }
        ]
      },
    };
  },
  components: { AppTemplate, SpinnerLoader, Calendar, Datepicker },
  computed: {
    ...mapGetters([
      "getProfile",
    ]),
  },
  async created() {
    this.minsData.weekDays = this.populateMinsCal(
      Number(dayjs().format("YYYY")),
      Number(dayjs().month()),
      "minsData"
    );
    const response = await this.$store.dispatch(CHECK_PROFILE_HAS_DATA);

    if (response === true) {
      this.setUser();
      this.getAllGamesEventsByClubId();
    } else {
      const clientId = JSON.parse(
        localStorage.getItem("the_w_selectedClientId")
      );
      this.getUserById(clientId);
    }
  },
  mounted() {},
  methods: {
    changeTimeFrom24To12jours(time) {
      let hours = time.substr(0, 2);
      let mins = time.substr(3, 2);
      var AmOrPm = hours >= 12 ? 'pm' : 'am';
      hours = (hours % 12) || 12;

      return `${hours}:${mins} ${AmOrPm}`;
    },
    async getEvents() {
      this.scheduleDatesDefault();
      const data = {
          action: "getEvents",
          season_start_date: this.scheduleStartDate,
          season_end_date: this.scheduleEndDate
        };

        const responce = await Teams.find(data).catch((error) => {
          console.log(error);
        });

        if (responce.status === 200) {

          if(responce.data.length > 0) {

            responce.data.map( item => {

              this.scheduleData.push({
                  title: item.title,
                  date: item.date,
                  time: item.start_time,
                  endTime: item.end_time,
                  teamId: item.teamId.toString(),
                  pitch_zone:  "",
                  gameType: "Other",
                });

              })

              this.setSchedulerTableCellHeight();

          }
        }
    },
    setSchedulerTableCellHeight() {
      let colOneTeamName = document.getElementsByClassName("col-one-teams")[0].children;
      const eventsWithData = this.scheduleData.map(event => ({
        date: event.date,
        teamId: event.teamId.toString(),
      }));

      for (let i = 1; i < colOneTeamName.length; i++) { 
        // Start from 1 to skip the first row
        const className = colOneTeamName[i].getAttribute("class");
        const teamIdText = className.replace(/\D/g, ""); // Extract team ID

        this.minsData.weekDays.forEach(day => {
          if (day.date) {
            const formattedDate = dayjs(day.date).format("YYYY-MM-DD");

            const matchingEvent = eventsWithData.find(event => event.date === formattedDate && event.teamId === teamIdText);
            
            if (matchingEvent) {
              const colOneTeamTableCell = colOneTeamName[i];
              const relatedCells = document.getElementsByClassName(`body body team-${matchingEvent.teamId}`);

              Array.from(relatedCells).forEach(cell => {
                if (cell.className === `body body team-${matchingEvent.teamId}`) {
                  cell.style.height = "10rem";
                  //cell.style.overflowY = "scroll";
                }
              });

              if (colOneTeamTableCell) {
                colOneTeamTableCell.style.height = "10rem";
              }
            }
          }
        });
      }
    },
    async saveEvent() {
      this.newEventData.date = this.formatDateForDB(this.newEventData.date);
      const data = {
          action: "saveEvent",
          listOfSelectedYear: this.listOfSelectedYear,
          ...this.newEventData,
        };

        const responce = await Teams.find(data).catch((error) => {
          console.log(error);
        });

        if (responce.status === 200) {
          this.getAllGamesEventsByClubId();
          this.newEventData = {};
          this.$bvModal.hide("addEvent");
            this.$bvToast.toast("Event saved", {
              title: "Saved",
              toaster: "b-toaster-bottom-right",
              solid: true,
              appendToast: true,
              variant: "success",
            });
        }
    },
    ageGroupAddRemove(data) {
      data.target.checked === true
        ? this.addToListOfSelectedTeamAgeGroups(data.target.value)
        : this.removeFromListOfSelectedAgeGroups(data.target.value);
    },
    removeFromListOfSelectedAgeGroups(year) {
      this.listOfSelectedYear = this.listOfSelectedYear.filter(
        (item) => item !== year
      );
    },
    addToListOfSelectedTeamAgeGroups(year) {
      if (!this.listOfSelectedYear.includes(year)) {
        this.listOfSelectedYear.push(year);

      }
    },
    openAddEventModal() {
      this.$bvModal.show("addEvent");
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
    },
    changeMonth(value, dataType) {
      let changeNumber = 0;
      let yearNumber = this[dataType].year;

      if (value === 1) {
        changeNumber = Number(dayjs().month(this[dataType].month).format("M"));
        if (this[dataType].month === 11) {
          changeNumber = 0;
          yearNumber = dayjs().year(yearNumber).add(1, 'year').format("YYYY");
        }
      } else {
        changeNumber = Number(dayjs().month(this[dataType].month).subtract(2, 'month').format("M"));
        if (this[dataType].month === 1) {
          changeNumber = 0;
          yearNumber = dayjs().year(yearNumber).subtract(1, 'year').format("YYYY");
        }
      }
 
      this[dataType].weekDays = this.populateMinsCal(
        Number(yearNumber),
        Number(changeNumber),
        dataType
      )

      const currentDate = `${yearNumber}-${changeNumber}-01`;

      this.scheduleStartDate = moment(currentDate).subtract(1, "months").format("YYYY-MM-DD");
      this.scheduleEndDate = moment(currentDate).add(2, "months").format("YYYY-MM-DD");

      this.getAllGamesEventsByClubId();
    },
    formatDateForDB(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatDateForHuman(date) {
      return date !== undefined && moment(date).isValid()
        ? moment(date).format("MMMM Do YYYY")
        : "";
    },
    formatDateForHumanShort(date) {
      return date !== undefined && moment(date).isValid()
        ? moment(date).format("MMMM Do, h:mm a")
        : "";
    },
    formatTime(date) {
      return date !== undefined && moment(date).isValid()
        ? moment(date).utc().format("h:mm a")
        : "";
    },
    populateSchedualDay() {
      this.scheduleData = [];
      this.teamGamesDataUnfilterded.map((game) => {
            let title = "";
            let time = "";
            let pitch_zone = null;

            if (game.gameType !== 'Training') {
              title = `Vs ${game.opposition} At ${game.locationName} ${game.postCode}`;
              time = `${this.formatTime(game.kickOffTime)} KO `;

            } else {
              title = `${game.gameType} At ${game.locationName} ${game.postCode}`;
              time = `${this.formatTime(game.kickOffTime)} - ${this.formatTime(game.endTime)}`;
              pitch_zone = game.pitch_zone;
            }

            this.scheduleData.push({
                title: title,
                date: game.matchDate,
                time: time,
                teamId: game.teamId,
                pitch_zone:  pitch_zone,
                gameType: game.gameType,
              });
      });

      this.getEvents(); 

    },
    scheduleDatesDefault() {
      if (this.scheduleStartDate === "" || this.scheduleEndDate === "") {
        this.scheduleStartDate = moment().subtract(1, "months").format("YYYY-MM-DD");
        this.scheduleEndDate = moment().add(2, "months").format("YYYY-MM-DD");
      }
    },
    async getAllGamesEventsByClubId() {
      this.scheduleDatesDefault();
      const data = {
          action: "getAllGamesEventsByClubId",
          season_start_date: this.scheduleStartDate,
          season_end_date: this.scheduleEndDate
        };

        const responce = await Games.find(data).catch((error) => {
          console.log(error);
        });

        if (responce.status === 200) {
          this.teamGamesDataUnfilterded = responce.data;
          this.teamGamesData = responce.data;

          this.populateSchedualDay();
        }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        this.getTeamsByUserId();
        this.getAllGamesEventsByClubId();
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
        this.getTeamsByUserId();
      }
    },
    populateMinsCal(year, month, dataType) {
      this[dataType].year = year;
      this[dataType].month = month;
      let date = new Date(year, month, 1);
      let dates = [];

      while (date.getMonth() === month) {
          const entry = {
              date: new Date(date),
              id: null,
          };

          if (dataType === "minsData") {
              entry.minsTotal = null;
          }

          dates.push(entry);
          date.setDate(date.getDate() + 1);
      }

      return dates;
    },
    getMonthName(dateNumber) {
      return dayjs().month(dateNumber).format('MMMM');
    },
    getDayFromDate(date) {
      return dayjs(date).format('ddd D');
    },
    async getTeamsByUserId() {
        const data = {
          action: "getTeamsDataById",
          userId: this.userData.id,
          clubId: this.userData.clubId,
        };
        const responce = await Teams.find(data).catch((error) => {
          console.log(error);
        });
        this.allTeamDataBeforFilterApplied = responce.data;
        this.clubTeams = responce.data;
    },
  },
};
</script>
<style lang="scss" scoped>

.event-schedual-club {
  .form__input {
    width:100%;
  }

}

.mins-stats-wrapper {
  border-top: 1px solid #c3cdd5;
  padding: 2rem;
  overflow: hidden;

  .totals {
    display:flex;
    gap:1rem;

    .total {
      border:1px solid #c3cdd5;
      margin: 1rem 0;
      padding: 1rem;
      min-width: 10rem;

      span {}
      p {
        font-size: 2rem;
        display: block;
        font-weight: bold;
        line-height: 2rem;

      }
    }
  }

  .date-year {
    display: block;
    text-align: center;
    margin: 0.5rem;
    width:100%;
    }
 
  .mins-stats-view {
    display: flex;
    background: white;
    border: 2px solid #c3cdd5;
    overflow-x: scroll;


    .col-one-teams {

      .body:first-of-type {
        height: auto;
      }

      .body {
        border:1px solid #c3cdd5;
        width: 11rem;
        padding: 0.5rem;
        font-size: 0.8rem;
        height: 5rem;
      }
    }

    .mins-stats-col-one {
      background: green;
      width: 10rem;
      height: 100%;
    }

    .events {
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;

    .mins-stats-col-day-wrapper {
      .head {
        white-space: nowrap;
      }
      .body {
        &:hover, &:focus {
          background: #eeeeee;
          cursor: pointer;
        }

        .buttons-wrapper {
            display: flex;
            gap: 0.3rem;
        }

        .main-button {
          margin:0;
          padding: 0.3rem;
        }

      }
      .head, .body {
        border:1px solid #c3cdd5;
        text-align: center;
        padding: 0.5rem 3rem;
        font-size:0.8rem;
      }

      .body {
        min-height: 2.325rem;
        border:1px solid #e9edf0;
        height: 5rem;
        padding: 0.5rem;

        .event-wrapper.training-style {
          border-left: 3px solid #3c5746;
          background: rgb(179, 220, 244);
        }

        .event-wrapper.league-style {
          border-left: 3px solid #3c5746;
          background: rgb(179, 244, 203);
        }

        .event-wrapper {
          margin-bottom:0.5rem;
          margin-bottom: 0.5rem;
          border-left: 3px solid;
          background: #e9edf0;
          padding: 0.2rem;

          .event-text {
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 188px;
        }

        }

        
      }

    }

    }

    
    
  }
}
</style>
