import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import Login from "@/views/auth/login";
import Register from "@/views/auth/register";
import TrainingPlans from "@/views/training-plans";
import Teams from "@/views/teams";
import Coaches from "@/views/coaches";
import Booking from "@/views/booking";
import Payments from "@/views/payments";
import paymentSuccess from "@/views/payments/success";
import paymentCanceled from "@/views/payments/canceled";
import Documents from "@/views/documents";
import Help from "@/views/help";
import Settings from "@/views/settings";
import Trialists from "@/views/trialist";
import PasswordRecovery from "@/views/passwordRecovery";
import App from "@/views/layouts/App";
import Home from "@/views/home";
import Dashboard from "@/views/dashboard";
import Profile from "@/views/profile";
import PtUserHome from "@/views/home";


Vue.use(Router);

async function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  if (await store.getters.isAuthenticated) {
    isAuthenticated = true;
  } else isAuthenticated = false;

  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    next("/login"); // go to '/login';
  }
}

/* const guardRoute = async (to, from, next) => {
  console.log(from);
  if (from.name === "login") {
    next();
  } else {
    const isAuthenticated = await store.getters.isAuthenticated;
    if (!isAuthenticated) {
      logout();
    } else {
      next();
    }
  }
}; */

function logout() {
  store.dispatch("AUTH_LOGOUT");
  router.push({ name: "login" });
  location.reload();
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/dashboard",
      name: "home",
      component: Dashboard,
      beforeEnter: guardMyroute
    },
    {
      path: "/pt-dashboard",
      name: "ptHome",
      component: Home,
      beforeEnter: guardMyroute
    },
    {
      path: "/pt-user-dashboard",
      name: "ptUserHome",
      component: PtUserHome,
      beforeEnter: guardMyroute
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      beforeEnter: guardMyroute
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      beforeEnter: guardMyroute
    },
    {
      path: "/teams",
      name: "teams",
      component: Teams,
      beforeEnter: guardMyroute
    },
    {
      path: "/trialists",
      name: "trialists",
      component: Trialists,
      beforeEnter: guardMyroute
    },
    {
      path: "/coaches",
      name: "coaches",
      component: Coaches,
      beforeEnter: guardMyroute
    },
    {
      path: "/booking",
      name: "booking",
      component: Booking,
      beforeEnter: guardMyroute
    },
    {
      path: "/training-plans/:trainingPlanUserId",
      name: "trainingPlans",
      component: TrainingPlans,
      props: true,
      beforeEnter: guardMyroute
    },
    {
      path: "/documents/",
      name: "documents",
      component: Documents,
      beforeEnter: guardMyroute
    },
    {
      path: "/help/",
      name: "help",
      component: Help,
      beforeEnter: guardMyroute
    },
    {
      path: "/payments/payAndSubs",
      name: "payments",
      component: Payments,
      beforeEnter: guardMyroute
    },
    {
      path: "/payments/success/:session_id",
      name: "paymentSuccess",
      component: paymentSuccess,
      beforeEnter: guardMyroute
    },
    {
      path: "/payments/cancel",
      name: "paymentCanceled",
      component: paymentCanceled,
      beforeEnter: guardMyroute
    },
    {
      path: "/login",
      name: "login",
      component: Login
      // beforeEnter: guardMyroute
    },
    {
      path: "/register/:clubId",
      name: "register",
      component: Register
      // beforeEnter: guardMyroute
    },
    {
      path: "/forgot-password/:key",
      name: "passwordRecovery",
      component: PasswordRecovery
      // beforeEnter: guardMyroute
    },
  ]
});
